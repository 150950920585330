<template>
  <div>
    <CustomMultiselect
      @input="onInput"
      :value="value"
      :multiple="true"
      :limit="3"
      :limitText="limitText"
      :show-labels="false"
      :disabled="disabled"
      :options="options"
      :track-by="trackingName"
      :label="labelName"
      :placeholder="placeholder"
      :closeOnSelect="closeOnSelect"
    ></CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';

export default {
  components: {
    CustomMultiselect,
  },
  props: {
    value: { type: Array },
    options: { type: Array, default: () => [] },
    placeholder: { type: String },
    trackingName: { type: String },
    labelName: { type: String },
    closeOnSelect: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    onInput($event) {
      this.$emit(this.labelName + 'Selected', $event || []);
    },
    limitText(count) {
      return '+' + count;
    },
  },
};
</script>
