var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('b-button-group',{staticClass:"mb-3",attrs:{"size":"sm"}},_vm._l((_vm.regions),function(region){return _c('b-button',{key:region,staticClass:"mr-1",staticStyle:{"width":"33%"},attrs:{"disabled":_vm.isBusy,"id":region,"variant":_vm.activeButton === region ? 'primary' : ''},on:{"click":_vm.onRegionFilterClick}},[_vm._v(" "+_vm._s(region)+" ")])}),1),(!_vm.isHistoryPage)?_c('FilterCustom',{staticClass:"mt-4",attrs:{"disabled":_vm.isBusy,"options":_vm.kuerzelFilterOptions,"value":_vm.kuerzelFilterValues,"placeholder":"Reisekürzel","trackingName":"kuerzelId","labelName":"kuerzelLabel"},on:{"kuerzelLabelSelected":_vm.setKuerzelFilter}}):_vm._e(),_c('b-button-group',{staticClass:"mt-4 mb-3",attrs:{"size":"sm"}},_vm._l((_vm.deutscheAbflughaefen),function(deutscherAbflughafen){return _c('b-button',{key:deutscherAbflughafen,staticClass:"mr-1",staticStyle:{"width":"33%"},attrs:{"id":deutscherAbflughafen,"disabled":_vm.isBusy,"variant":_vm.deutscheAbflughaefenSelected.includes(deutscherAbflughafen)
          ? 'primary'
          : _vm.deutscheAbflughaefenSelected.length === 0 && deutscherAbflughafen === 'Alle'
          ? 'primary'
          : ''},on:{"click":_vm.onDeutscherAbflughafenFilterClick}},[_vm._v(" "+_vm._s(deutscherAbflughafen)+" ")])}),1),_c('b-button-group',{staticClass:"mt-4 mb-3",attrs:{"size":"sm"}},_vm._l((_vm.prioritaeten),function(prioritaet){return _c('b-button',{key:prioritaet.value,staticClass:"mr-1",staticStyle:{"width":"33%"},attrs:{"disabled":_vm.isBusy,"id":prioritaet.name,"variant":_vm.prioritaetenSelected.includes(prioritaet.value)
          ? 'primary'
          : _vm.prioritaetenSelected.length === 0 && prioritaet.name === 'Alle'
          ? 'primary'
          : ''},on:{"click":_vm.onPrioritaetFilterClick}},[_vm._v(" "+_vm._s(prioritaet.name)+" ")])}),1),_c('b-button-group',{staticClass:"mt-4 mb-3",attrs:{"size":"sm"}},_vm._l((_vm.regionsmanagers),function(regionsmanager){return _c('b-button',{key:regionsmanager,staticClass:"mr-1",staticStyle:{"width":"33%"},attrs:{"id":regionsmanager,"disabled":_vm.isBusy,"variant":_vm.regionsmanagersSelected.includes(regionsmanager)
          ? 'primary'
          : _vm.regionsmanagersSelected.length === 0 && regionsmanager === 'Alle'
          ? 'primary'
          : ''},on:{"click":_vm.onRegionsmanagerFilterClick}},[_vm._v(" "+_vm._s(regionsmanager)+" ")])}),1),_c('b-form-checkbox',{staticClass:"mt-4",attrs:{"disabled":_vm.isBusy,"switch":""},on:{"change":_vm.onChangeAkzeptiertCheckbox},model:{value:(_vm.nurAkzeptiertCheckbox),callback:function ($$v) {_vm.nurAkzeptiertCheckbox=$$v},expression:"nurAkzeptiertCheckbox"}},[_vm._v(" Nur akzeptiert ")]),_c('div',{staticClass:"w-100 mt-8 flex-grow-1 flex-column"},[_c('b-button',{attrs:{"block":"","variant":"danger","disabled":!_vm.isAnyFilterApplied},on:{"click":_vm.resetAllFilters}},[_vm._v(" Alle Filter zurücksetzen ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }